function addMobileNavClasses() {
    jQuery('.menu-primary-menu-container').addClass('showNav');
    jQuery('.overlay').addClass('show');
}
function removeMobileNavClasses() {
    jQuery('.menu-primary-menu-container').removeClass('showNav');
    jQuery('.overlay').removeClass('show');
}
jQuery(function ($) {
    $('.mobile-nav-toggle-container').click(function () {
        addMobileNavClasses();
    });
    $('.overlay').click(function () {
        removeMobileNavClasses();
    });
});